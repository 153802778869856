import React, { Fragment } from "react";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import IntlMessages from "util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

export default class SidebarContent extends React.Component {
    render() {
        return (
            <Fragment>
                <div className="logo">
                    <img
                        src={require("assets/images/logo.png")}
                        alt="Firstoeat"
                    />
                </div>

                <Menu
                    defaultOpenKeys={[this.props.match]}
                    theme="dark"
                    mode="inline"
                    className="scrollList"
                >
                    <MenuItemGroup
                        key="menu"
                        title={<IntlMessages id="sidebar.menu" />}
                    >
                        <Menu.Item key="main">
                            <Link to="/admin/dashboard">
                                <Icon type="dashboard" />
                                <IntlMessages id="sidebar.main" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="clientes">
                            <Link to="/admin/clientes">
                                <Icon type="team" />
                                <IntlMessages id="sidebar.client" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="financial">
                            <Link to="/admin/financial">
                                <Icon type="credit-card" />
                                <IntlMessages id="sidebar.financial" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="analytcs">
                            <Link to="/admin/analytics">
                                <Icon type="area-chart" />
                                <IntlMessages id="sidebar.analytics" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="contact">
                            <Link to="/admin/contact">
                                <Icon type="mail" />
                                <IntlMessages id="sidebar.contact" />
                            </Link>
                        </Menu.Item>

                        <SubMenu
                            key="region"
                            title={
                                <div>
                                    <Icon type="global" />
                                    <IntlMessages id="sidebar.menu.region" />
                                </div>
                            }
                        >
                            <Menu.Item key="select_region">
                                <Link to="/admin/region/list">
                                    <Icon type="bars" />
                                    <IntlMessages id="sidebar.menu.region.select" />
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="create_region">
                                <Link to="/admin/region/create">
                                    <Icon type="plus-circle" />
                                    <IntlMessages id="sidebar.menu.region.create" />
                                </Link>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="franchisee"
                            title={
                                <div>
                                    <Icon type="team" />
                                    <IntlMessages id="sidebar.menu.franchisee" />
                                </div>
                            }
                        >
                            <Menu.Item key="create_notifications">
                                <Link to="/admin/franchisees/list">
                                    <Icon type="bars" />
                                    <IntlMessages id="sidebar.menu.franchisee.select" />
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="create_franchisee">
                                <Link to="/admin/franchisees/create">
                                    <Icon type="plus-circle" />
                                    <IntlMessages id="sidebar.menu.franchisee.create" />
                                </Link>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="restaurants"
                            title={
                                <div>
                                    <Icon type="shop" />
                                    <IntlMessages id="sidebar.restaurants.menu" />
                                </div>
                            }
                        >
                            <Menu.Item key="select_restaurants">
                                <Link to="/admin/restaurants/list">
                                    <Icon type="bars" />
                                    <IntlMessages id="sidebar.restaurants.submenu.restaurants.list" />
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="create_restaurants">
                                <Link to="/admin/restaurants/add">
                                    <Icon type="plus-circle" />
                                    <IntlMessages id="sidebar.restaurants.submenu.restaurants.add" />
                                </Link>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="coupons"
                            title={
                                <div>
                                    <Icon type="file-text" />
                                    <IntlMessages id="sidebar.tickets.menu" />
                                </div>
                            }
                        >
                            <Menu.Item key="select_coupons">
                                <Link to="/admin/coupons/list">
                                    <Icon type="bars" />
                                    <IntlMessages id="sidebar.tickets.submenu.select" />
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="create_coupons">
                                <Link to="/admin/coupons/add">
                                    <Icon type="plus-circle" />
                                    <IntlMessages id="sidebar.tickets.submenu.create" />
                                </Link>
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item key="reserves">
                            <Link to="/admin/reserves">
                                <Icon type="tags" />
                                <IntlMessages id="sidebar.reserves" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="leads">
                            <Link to="/admin/leads">
                                <Icon type="idcard" />
                                <IntlMessages id="sidebar.leads" />
                            </Link>
                        </Menu.Item>
                    </MenuItemGroup>


                    <Menu.Item key="privacy-policy">
                        <Link to="/admin/privacy-policy">
                            <Icon type="solution" />
                            Politicas de privacidade
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="terms-conditions">
                        <Link to="/admin/use-terms">
                            <Icon type="book" />
                            Termos e condições
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="logs">
                        <Link to="/admin/logs">
                            <Icon type="read" />
                            Logs
                        </Link>
                    </Menu.Item>

                    <SubMenu
                        key="FAQ"
                        title={
                            <div>
                                <Icon type="question-circle" />
                                FAQ
                            </div>
                        }
                    >
                        <Menu.Item key="create_notifications">
                            <Link to="/admin/faq/list">
                                <Icon type="bars" />
                                <IntlMessages id="faq.list" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="create_franchisee">
                            <Link to="/admin/faq/create">
                                <Icon type="plus-circle" />
                                <IntlMessages id="faq.add" />
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="Notificações"
                        title={
                            <div>
                                <Icon type="question-circle" />
                                Notificações
                            </div>
                        }
                    >
                        <Menu.Item key="create_notify">
                            <Link to="/admin/notify/list">
                                <Icon type="bars" />
                                Listar Notificações
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="create_not">
                            <Link to="/admin/notify/create">
                                <Icon type="plus-circle" />
                                Adicionar Notificações
                            </Link>
                        </Menu.Item>
                    </SubMenu>

                    <Menu.Item key="system-config">
                        <Link to="/admin/system-config">
                            <Icon type="setting" />
                            Configurações
                        </Link>
                    </Menu.Item>
                </Menu>
            </Fragment>
        );
    }
}
