// FRANCHISEE
export const ADD_FRANCHISEE = "ADD_FRANCHISEE";
export const GET_ALL_FRANCHISEES = "GET_ALL_FRANCHISEES";
export const GET_FRANCHISEE = "GET_FRANCHISEE";

// LOGS
export const GET_LOGS = "GET_LOGS";

// PRIVACY_POLICY
export const ADD_PRIVACY_POLICY = "ADD_PRIVACY_POLICY";
export const GET_ALL_PRIVACY_POLICY = "GET_ALL_PRIVACY_POLICY";
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const UPDATE_PRIVACY_POLICY = "UPDATE_PRIVACY_POLICY";

// SYSTEM_CONFIG
export const ADD_SYSTEM_CONFIG = "ADD_SYSTEM_CONFIG";
export const GET_ALL_SYSTEM_CONFIG = "GET_ALL_SYSTEM_CONFIG";
export const GET_SYSTEM_CONFIG = "GET_SYSTEM_CONFIG";
export const UPDATE_SYSTEM_CONFIG = "UPDATE_SYSTEM_CONFIG";

// USE_TERM
export const ADD_USE_TERM = "ADD_USE_TERM";
export const GET_ALL_USE_TERMS = "GET_ALL_USE_TERMS";
export const GET_USE_TERM = "GET_USE_TERM";
export const UPDATE_USE_TERM = "UPDATE_USE_TERM";

// FAQ
export const ADD_FAQ = "ADD_FAQ";
export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const GET_FAQ = "GET_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";

// NOTIFICATION
export const ADD_NOTIFY = "ADD_NOTIFY";
export const GET_ALL_NOTIFYS = "GET_ALL_NOTIFYS";
export const GET_NOTIFY = "GET_NOTIFY";
export const UPDATE_NOTIFY = "UPDATE_NOTIFY";

// REGION
export const ADD_REGION = "ADD_REGION";
export const GET_ALL_REGIONS = "GET_ALL_REGIONS";
export const GET_REGION = "GET_REGION";
export const UPDATE_REGION = "UPDATE_REGION";

export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_CITIES = "GET_CITIES";

export const GET_ACCESS_RESTAURANT = "GET_ACCESS_RESTAURANT";
export const GET_OCCUPATION = "GET_OCCUPATION";
export const GET_PER_RESTAURANT = "GET_PER_RESTAURANT";
export const GET_ORDERS_CURRENT = "GET_ORDERS_CURRENT";
export const GET_ORDERS_PAST = "GET_ORDERS_PAST";
export const GET_USER_BY_REGION = "GET_USER_BY_REGION";
export const GET_USER_BY_REGION_AGGREGATED = "GET_USER_BY_REGION_AGGREGATED";
export const GET_USER_BY_REGION_AGGREGATED_PAST =
    "GET_USER_BY_REGION_AGGREGATED_PAST";
export const GET_CONTACTS = "GET_CONTACTS";

export const GET_RESTAURANT = "GET_RESTAURANT";

// LEADS
export const GET_ALL_LEADS = "GET_ALL_LEADS";
export const GET_ALL_LEADS_WITHOUT_LIMIT = "GET_ALL_LEADS_WITHOUT_LIMIT";

export const CLEAR_CITIES = "CLEAR_CITIES";
