import Loadable from "react-loadable";

import LoadingComponent from "../../commom/components/loader";

export default [
    {
        path: "/admin/dashboard",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Dashboard"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/clientes",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Clientes"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/financial",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Financial"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/materials",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Materials"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/reserves",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Reserves"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/notifications/add",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Notifications/add"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/notifications/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Notifications/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/notifications/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Notifications/edit"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/coupons/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Coupons/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/coupons/add",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Coupons/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/coupons/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Coupons/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/restaurants/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Restaurants/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/restaurants/add",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Restaurants/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/restaurants/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Restaurants/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/galery/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/GaleryPhotos/ListGalery"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/galery/add",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/GaleryPhotos/FormGalery"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/galery/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/GaleryPhotos/FormGalery"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/calendar/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Calendar/ListCalendar"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/calendar/add",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Calendar/FormCalendar"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/calendar/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Calendar/FormCalendar"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/featured/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Featured/ListFeatured"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/featured/add",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Featured/FormFeatured"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/featured/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Featured/FormFeatured"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/assessments/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Assessments/ListAssessments"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/assessments/details",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Assessments/AssessmentDetail"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/assessments/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Assessments/ListAssessments"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/users/",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Users/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/users/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Users/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/users/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("../../modules/franchisee/pages/Users/edit"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/users/statistics",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Users/TableStatistics"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/franchisees/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/franchisee/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/franchisees/create",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/franchisee/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/franchisees/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/franchisee/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/logs",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/logs"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/faq/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/faq/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/faq/create",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/faq/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/faq/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/faq/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/notify/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/notify/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/notify/create",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/notify/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/notify/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/notify/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/privacy-policy",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/privacypolicy"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/use-terms",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/useterms"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/system-config",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/systemconfig"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/leads",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/leads"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/region/list",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/region/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/region/create",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/region/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/region/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/region/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/analytics",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/Analytics"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/contact",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/contact"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/restaurants/reviews/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("./pages/reviews"),
            loading: LoadingComponent
        })
    },
    {
        path: "/admin/reserves/refund/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () =>
                import("../../modules/franchisee/pages/Reserves/refund"),
            loading: LoadingComponent
        })
    }
];
