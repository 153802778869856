import { combineReducers } from "redux";

import user from "../modules/auth/store/reducer";
import materials from "../modules/franchisee/store/reducers/reducer-materials";
import reserves from "../modules/franchisee/store/reducers/reducer-reserves";
import coupons from "../modules/franchisee/store/reducers/reducer-coupons";
import notifications from "../modules/franchisee/store/reducers/reducer-notifications";
import restaurants from "../modules/franchisee/store/reducers/reducer-restaurants";
import users from "../modules/franchisee/store/reducers/reducer-users";
import financial from "../modules/franchisee/store/reducers/reducer-financial";
import local from "../modules/franchisee/store/reducers/reducer-local";
import analytics from "~/modules/franchisee/store/reducers/reducer-analytics";

import resUser from "../modules/restaurant/store/reducers/reducer-restaurants";
import resOrders from "../modules/restaurant/store/reducers/reducer-orders";
import resReviews from "../modules/restaurant/store/reducers/reducer-reviews";
import resAnalytics from "../modules/restaurant/store/reducers/reducer-analytics";

// admin
import systemConfig from "~/modules/admin/store/reducers/reducer-system-config";
import franchisees from "~/modules/admin/store/reducers/reducer-franchisees";
import logs from "~/modules/admin/store/reducers/reducer-logs";
import faq from "~/modules/admin/store/reducers/reducer-faq";
import notify from "~/modules/admin/store/reducers/reducer-notify";
import privacyPolicy from "~/modules/admin/store/reducers/reducer-privacy-policy";
import useTerms from "~/modules/admin/store/reducers/reducer-use-terms";
import locals from "~/modules/admin/store/reducers/reducer-local";
import leads from "~/modules/admin/store/reducers/reducer-leads";
import contact from "~/modules/admin/store/reducers/reducer-contact";
import restaurant from "~/modules/admin/store/reducers/reducer-restaurant";

export default combineReducers({
    user,
    financial,
    materials,
    reserves,
    coupons,
    notifications,
    restaurants,
    users,
    resUser,
    resOrders,
    resReviews,
    local,
    systemConfig,
    franchisees,
    logs,
    faq,
    notify,
    privacyPolicy,
    useTerms,
    locals,
    leads,
    analytics,
    contact,
    restaurant,
    resAnalytics
});
